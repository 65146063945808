import styled, { css } from "styled-components"
import { Xmeetup } from "./community"
import howwework from "../images/focus2.jpg"
import contact from "../images/get-in-touch-min.jpg"
import clients from "../images/about/achievements.jpg"
import whatwedo from "../images/about/sketching-background1.png"
import family from "../images/about/xtalents-test-min.gif"
import community from "../images/devoxx/community-1-min.jpg"
import devoxx from "../images/devoxx-logo-black-small.png"
import whatwedobefore from "../images/services/whatwedobefore.png"
import whatwedoafter from "../images/services/whatwedoafter.png"
import weoffer from "../images/about/xlog.png"

import { Link } from "gatsby"
import {
  LoaderRipple,
  InTouch1,
  InTouch2,
  InTouch3,
  InTouch4,
} from "./keyframes"

const LayoutContainer = styled.div`
  font-family: Quicksand;
  background-color: ${props => props.theme.white};
`

const Section = styled.section`
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-color: ${props => props.bgcolor || "transparent"};
  padding: ${props => props.padding || "30px 0"};
  margin: ${props => props.margin || 0};
  ${props =>
    props.flex &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
    `}

  ${props =>
    props.devoxx &&
    css`
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0.05;
        background: url(${devoxx}) repeat 0 0;
        z-index: 0;
      }
    `}
  ${props =>
    props.hoWework &&
    css`
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 0;
      }
      position: relative;
      background-image: url(${howwework});
    `}

  ${props =>
    props.gadient &&
    css`
      background: linear-gradient(
        180deg,
        rgba(99, 52, 131, 1) 44%,
        rgba(43, 29, 72, 1) 86%
      );
    `}

    

  ${props =>
    props.whatwedo &&
    css`
      background: linear-gradient(
        -129deg,
        ${props.theme.primaryLightColor},
        ${props.theme.primaryColor}
      );

      background-size: 100% 100%;
      padding-top: 60px;
      position: relative;

      &:before {
        position: absolute;
        top: -1px;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        min-height: calc(100% + 2px);
        background-repeat: no-repeat;
        background-image: url(${whatwedobefore});
        background-position: top;
        background-size: contain;
      }

      // &:after {
      //   background-position: bottom;
      //   background-size: contain;
      //   background-image: url(${whatwedoafter});
      // }

      ${({ theme }) => theme.sm`
        padding: 90px 0 50px!important;
      `}
    `}


  ${({ theme }) => theme.sm`
    padding: 30px 0;
  `}
`

const Container = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || "1200px"};
  margin: 0 auto;
  padding: 0 3rem;
  height: ${({ headerHeight, theme, height }) =>
    headerHeight ? theme.headerHeight : height ? height : "100%"};
  ${props =>
    props.flex &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}
  ${props =>
    props.flexCenter &&
    css`
      justify-content: space-between;
      align-items: center;
    `}
  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}
  ${props =>
    props.bgcolor &&
    css`
      background-color: white;
    `};

  ${({ theme }) => theme.md`
    padding: 0 2rem;
  `}
`
const Main = styled.main`
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  margin-bottom: ${({ margin, theme }) =>
    margin ? theme.footerHeight : theme.footerHeight};
  // box-shadow: 0 7px 15px 0 rgba(165, 165, 165, 0.2);
  // ${({ theme }) => theme.sm`
  //   margin-bottom: 0;
  // `}
`
const SectionTitle = styled.h2`
  text-align: ${props => props.align || "center"};
  margin: ${props => props.space || "0 0 40px"};
  font-weight: 800;
  font-family: Lato;
  width: ${props => props.width || "100%"};
  font-size: ${props => props.size || "36px"};
  color: ${props => props.color || props.theme.primaryColor};
  /* text-transform: ${props => props.transform || "none"}; */
  letter-spacing: ${props => props.letterSpacing || "normal"};
  div {
    color: ${props =>
      props.lightChild ? "#F7942E" : "inherit"};
    display: ${props => (props.block ? "block" : "inline")};
  }
  span {
    // font-size: 19px;
    // line-height: 28px;
    // color: #666;
    // display: block;
    // padding-top: 15px;
    // font-weight: 300;
    // text-transform: none;

    font-size: 17px;
    line-height: 25px;
    color: #555;
    display: block;
    padding-top: 15px;
    font-weight: 400;
    text-transform: none;
    font-family: Lato;
  }

  ${({ theme }) => theme.sm`
    width: 100%;
    font-size: 30px;
  `}
`
const SubTitle = styled.h3`
  text-align: ${props => props.align || "center"};
  margin: ${props => props.space || "15px 0"};
  font-weight: 500;
  font-family: Lato;
  width: ${props => props.width || "100%"};
  font-size: ${props => props.size || "20px"};
  color: ${props => props.color || props.theme.primaryColor};
  text-transform: ${props => props.transform || "none"};
`
const Banner = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding: 150px 0;
  position: relative;
  margin-top: ${props => props.theme.headerHeight};

  ${props =>
    props.contact &&
    css`
      background-position: 100% 0;
      background-image: url(${contact});
    `}
  
  ${props =>
    props.community &&
    css`
      background-color: white;
      background-position: 0 0;
      background-image: url(${community});
    `}

  ${props =>
    props.clients &&
    css`
      background-size: contain;
      background-color: white;
      background-repeat: repeat;
      background-image: url(${clients});
    `}

  ${props =>
    props.whatwedo &&
    css`
      background-size: contain;
      background-repeat: repeat;
      background-image: url(${whatwedo});
    `}

  ${props =>
    props.family &&
    css`
      background-image: url(${family});
    `}

    ${props =>
      props.download &&
      css`
        padding: 0px;
        height: 44vh;
        background-image: url(${weoffer});
      `}
  ${props =>
    props.overlay &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${props =>
          props.community ? "rgba(44, 29, 73, 0.5)" : "rgba(44, 29, 73, 0.65)"};
      }
    `}

  & > * {
    position: relative;
    z-index: 1;
  }

  ${({ theme }) => theme.sm`
    background-size: cover;
    background-repeat: -no-repeat;
  `}
`

const PrimaryButton = styled.a`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  padding: 15px 20px;
  margin: ${props => props.space || 0};
  color: white;
  overflow: hidden;
  box-shadow: 0 0 60px rgba(43, 29, 72, 0.3);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background: ${props => props.theme.primaryColor};
  transition: all 300ms;
  border: 2px solid ${props => props.theme.primaryColor};
  &:hover {
    color: ${props => props.theme.primaryColor};
    background: white;
  }
`
const NewEdition = styled.a`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  padding: 15px 20px;
  margin: ${props => props.space || 0};
  color: white;
  overflow: hidden;
  box-shadow: 0 0 60px rgba(43, 29, 72, 0.3);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background: #A45FA7;
  transition: all 300ms;
  border: 2px solid;
  border-color: #A45FA7;
  &:hover {
    color: ${props => props.theme.primaryColor};
    background: white;
  }
`

const OutLineBtn = styled(Link)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  padding: 12px 20px;
  border-radius: 3px;
  margin: ${props => props.space || 0};
  color: ${({ theme }) => theme.primaryColor};
  overflow: hidden;
  box-shadow: 0 0 60px rgba(43, 29, 72, 0.3);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background: ${props => props.theme.primaryColor};
  background: white;
  transition: all 300ms;
  border: 2px solid ${props => props.theme.primaryColor};
  &:hover {
    color: ${props => props.theme.primaryColor};
    background: white;
  }
`

const SeeMore = styled.span`
  display: inline-block;
  padding: 5px;
  font-size: 14px;
  font-family: Lato;
  cursor: pointer;
  color: ${props => props.theme.primaryColor};
  text-decoration: underline;

  ${Xmeetup}:hover & {
    color: white;
  }
`

const LoaderContainer = styled.div`
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 15px auto;
  div {
    position: absolute;
    border: 4px solid ${props => props.theme.primaryColor};
    opacity: 1;
    border-radius: 50%;
    animation: ${LoaderRipple} 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -1s;
    }
  }

  ${props =>
    props.absolute &&
    css`
      left: 50%;
      top: 50%;
      margin: 0;
      transform: translate(-50%, -50%);
    `}
`

const Success = styled.div`
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  color: #270;
  background-color: #dff2bf;
`

const NotFoundContainer = styled.div`
  background-color: ${props => props.theme.primaryColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 50px;
  img {
    display: block;
    width: 100%;
    max-width: 600px;
    height: auto;
  }
  p {
    color: white;
    font-family: Lato;
    font-weight: 700;
    font-size: 30px;
    margin: -60px 0 30px;

    ${({ theme }) => theme.sm`
      margin: 0;
  `}
  }
`

const GetInTouch = styled(Link)`
  margin: 30px auto;
  font-size: ${props => (props.small ? "18px" : "20px")};
  font-weight: 900;
  text-decoration: none;
  display: block;
  padding: ${props => (props.small ? "15px 30px" : "20px 50px")};
  position: relative;
  color: ${props => (props.reverse ? "white" : "#551a8b")};
  overflow: hidden;
  box-shadow: 0 0 120px
    ${props =>
      props.reverse ? "rgba(255, 255, 255, 0.4)" : "rgba(43, 29, 72, 0.3)"};
  background: ${props =>
    props.reverse ? props.theme.primaryColor : "rgba(224, 224, 224, 0.2)"};
  span {
    position: absolute;
  }
  span:nth-child(1) {
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(
      to right,
      ${props => (props.reverse ? props.theme.primaryColor : "white")},
      ${props => props.theme.primaryLightColor}
    );
    animation: ${InTouch1} linear 2s infinite;
  }

  span:nth-child(2) {
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(
      to bottom,
      ${props => (props.reverse ? props.theme.primaryColor : "white")},
      ${props => props.theme.primaryLightColor}
    );
    animation: ${InTouch2} linear 2s infinite;
    animation-delay: 1s;
  }

  span:nth-child(3) {
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(
      to left,
      ${props => (props.reverse ? props.theme.primaryColor : "white")},
      ${props => props.theme.primaryLightColor}
    );
    animation: ${InTouch3} linear 2s infinite;
  }

  span:nth-child(4) {
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(
      to top,
      ${props => (props.reverse ? props.theme.primaryColor : "white")},
      ${props => props.theme.primaryLightColor}
    );
    animation: ${InTouch4} linear 2s infinite;
    animation-delay: 1s;
  }
`

export {
  NotFoundContainer,
  Success,
  LoaderContainer,
  SeeMore,
  PrimaryButton,
  OutLineBtn,
  LayoutContainer,
  Section,
  Container,
  Main,
  SectionTitle,
  Banner,
  SubTitle,
  GetInTouch,
  NewEdition,
}
