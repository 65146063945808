import styled from "styled-components"
import { Link as LinkGatsby } from "gatsby"
import { ArrowFooter, ArrowFooterTop } from "./keyframes"

const Footer = styled.footer`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${props => props.theme.footerHeight};
  text-align: center;
  background-color: #d1b86b;
  background-color: #32243e;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.white};

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 60%;
    left: 65%;
    width: 200%;
    height: 170%;
    transform: translate(-50%, 0) rotateY(0) rotate(40deg);
    transform-origin: 50% 0;
    background: linear-gradient(
      to left,
      ${props => props.theme.primaryLightColor},
      ${props => props.theme.primaryColor} 50%,
      rgba(58, 46, 84, 0.5) 50%,
      #171717 100%
    );
    z-index: -1;
  }

  // ${({ theme }) => theme.sm`
  //   position: static;
  //   height: auto;
  //   overflow: hidden;
  // `}


  .icon-right-arrow {
    font-size: 60px;
    display: block;
    transition: animation 2s;
    ${({ theme }) => theme.sm`
      font-size: 50px;
    `}
  }

`
const H2 = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  margin: 35px 0 0;
  text-transform: uppercase;

  ${({ theme }) => theme.sm`
    margin-top: 20px;
  `}
`

const H1 = styled.h1`
  font-weight: 400;
  line-height: 50px;
  margin: 0.5rem 0 2rem;
  font-size: 42px;
  color: rgba(165, 165, 165, 0.7);
  ${({ theme }) => theme.sm`
    margin: 0.4rem 0 1.5rem;
    font-size: 30px;
  `}
`

const CopyRight = styled.div`
  font-weight: 400;
  line-height: 50px;
  margin: 0;
  font-size: 14px;
  color: #9e9e9e;

  .icon-like {
    margin: 0 10px;
    font-size: 23px;
  }

  ${({ theme }) => theme.sm`
    line-height: 25px;
    padding: 0 30px;

    .icon-like {
      font-size: 18px;
    }
  `}
`

const Link = styled(LinkGatsby)`
  text-decoration: none;
  color: white;
  font-family: Lato;

  .icon-right-arrow {
    animation: ${ArrowFooter} 2s infinite;
  }
`

const ScrolToTopStyle = styled.span`
  .icon-right-arrow {
    transform: rotate(-90deg);
    font-size: 50px;
    animation: ${ArrowFooterTop} 2s infinite;
  }
`

export const Arrow = styled.div`
  cursor: pointer;
  min-height: 80px;
  overflow: hidden;
`

const SocialIcons = styled.div`
  margin-right: 50px;
  width: 25%;
  display: flex;
  flex-direction: column;

  a {
    margin: 13px 0;
    display: block;
    color: white;
    font-size: 24px;
    text-decoration: none;
  }

  ${({ theme }) => theme.sm`
    margin: 40px auto;
  `}
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
`

export {
  FooterContent,
  Footer,
  H2,
  H1,
  CopyRight,
  Link,
  SocialIcons,
  ScrolToTopStyle,
}
